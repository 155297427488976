import Layout from '~/components/Layout';

export default function Home() {
  return (
    <Layout title="404" hasTabBar>
      <div className="text-center">
        <h1>404 - Page Not Found</h1>
      </div>
    </Layout>
  );
}
